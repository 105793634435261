import React,  { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Theme1 from "./themes/Theme1";
import Theme2 from "./themes/Theme2";



function App() {

  const theme = createTheme({
    palette: {
      themePortal: {
        main: '#ffffff',
      }
    },
    components:{
      MuiInput: {
        styleOverrides: {
          root: {
            '&::before': {
              borderBottom: '1px solid #aaaaaa',
              color: '#aaaaaa'
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '1px solid #aaaaaa',
            },
            '&.Mui-focused:after': {
              borderBottom: '1px solid #aaaaaa',
            },
          },
        },
      },
    }
  });
  
  var url_string = window.location.href;
  var url = new URL(url_string);
  var s = url.searchParams.get("s");
  var h = url.searchParams.get("h");

  const [secret,setSecret] = useState(s)
  const [host,setHost] = useState(h)
  const [_404,set404] = useState(1)
  const [comp, setComp] = useState([])
  const [error,setError] = React.useState([])
  const [loading,setLoading] = React.useState(false)
  const [stGetdata,setStGetdata] = useState(true)

  const config = { 
    home : 'https://portal.netmaster.id/',
    //home : 'https://localhost:3000/',
    hostApi : 'https://api-portal.netmaster.id/',
    secret : s,
    comp: comp,
    routerIP : h,
    formColor: comp.form_color, //'#ffffff',
    fillColor: comp.fill_color, //'#E41534',
    borderColor: comp.border_color, //'#aaaaaa',
    textColor: comp.text_color, //'#666666',
    buttonColor: comp.button_color, //'#E41534',
    buttonTextColor: comp.button_text_color, //'#ffffff',
  }

  const getData = (data) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/json");
    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    
    fetch(config.hostApi + 'setting', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setComp(result.data)
            setStGetdata(false)
            setLoading(false)
            set404(2)
          }else{
            setError(result.errors)
            setLoading(false)
            set404(0)
            
          }
          //console.log(result)
        })
        .catch((error)=>{
          setLoading(false)
          //console.log(error)
          set404(0)
        })
        
  }

  useEffect(() => {
    getData()
    //console.log(comp)
  },[])

  if(_404 === 0){
    return(<h1 style={{color:'#ffffff'}}>Error 404</h1>)
  }else if(_404 === 1){
    return(<h1 style={{color:'#ffffff'}}>Loading</h1>)
  }else{
    return(
      <ThemeProvider theme={theme}>
        {comp.theme == 1 ? <Theme1 config={config} /> :''}
        {comp.theme == 2 ? <Theme2 config={config} /> :''}
      </ThemeProvider>
    )
  }
  
}

export default App;
