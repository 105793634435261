import React,  { useRef, useState, useEffect } from "react";
import { useTimer } from 'react-timer-hook';
import { useForm, SubmitHandler } from "react-hook-form"
import { Row, Col } from "react-bootstrap";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { MuiTelInput } from 'mui-tel-input'
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';
import { makeStyles } from '@mui/styles';


import '../css/bootstrap.min.css'
import '../css/style-theme1.css'

const useStyles = makeStyles ({
  transparentBackground: {
    backgroundColor: 'transparent',
    width: '100%'
  },
})

function Theme1(props) {
  const { register, setValue, getValues, formState: { errors }, handleSubmit } = useForm()
  
  const [host,setHost] = useState(props.config.routerIP)
  const [secret,setSecret] = useState(props.config.secret)
  const [_404,set404] = useState(1)
  const [form,setForm] = useState(1)
  const [timer,setTimer] = useState(60)
  const [tel, setTel] = React.useState('+62')
  const [otp, setOtp] = React.useState('')
  const [error,setError] = React.useState([])
  const [loading,setLoading] = React.useState(false)
  const [stGetdata,setStGetdata] = useState(true)
  
  const classes = useStyles();

  const time = new Date();
  time.setSeconds(time.getSeconds() + timer); 

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ time, onExpire: () => console.warn('onExpire called') });


  const authCode = (data) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const formData = new URLSearchParams(new FormData());
    let telp = tel.replace(/ |\+/gi,'')
    formData.append("otp", otp)
    formData.append("wa_number", telp)

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };
    
    fetch(props.config.hostApi + 'authenticate', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setLoading(false)
            console.log('onConnect')
            window.location.href= 'http://' + host + '/login.html?s=' + secret;
          }else{
            setError(result.message)
            setLoading(false)
          }
        })
        .then(()=>{
          setLoading(false)
        })
        
  }

  const onSubmit = (data) => {
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const formData = new URLSearchParams(new FormData());
    let telp = tel.replace(/ |\+/gi,'')
    formData.append("name", getValues('name'))
    formData.append("wa_number", telp)
    formData.append("data", '[{"email":"'+ getValues('email')+'"}]')

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    setLoading(false)
    setForm(2)
    fetch(props.config.hostApi + 'signup', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setLoading(false)
            setForm(2)
            
            const time = new Date();
            time.setSeconds(time.getSeconds() + timer);
            restart(time)
          }else{
            setError(result.errors)
            setLoading(false)
          }
        })
        .then(()=>{
          setLoading(false)
        })
        
  }

  
  const resend = () =>{
    setLoading(true)
    const myHeaders = new Headers();
    myHeaders.append("Secret",secret);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const formData = new URLSearchParams(new FormData());
    let telp = tel.replace(/ |\+/gi,'')
    formData.append("name", getValues('name'))
    formData.append("wa_number", telp)
    formData.append("data", '[{"email":"'+ getValues('email')+'"}]')

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    fetch(props.config.hostApi + 'signup', requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success){
            setLoading(false)
            
            setForm(2)
            const time = new Date();
            time.setSeconds(time.getSeconds() + timer);
            restart(time)
          }else{
            setError(result.errors)
            setLoading(false)
          }
        })
        .then(()=>{
          setLoading(false)
        })
  }
  const otpChange = (newValue) => {
    setOtp(newValue)
  }
  const telChange = (newValue) => {
    setTel(newValue)
  }
  useEffect(() => {
  },[])

  return (
      <>
        <style>{`
            * {
              padding: 0;
              margin: 0
            }
            h1,h2,h3,h4,h5,h6{
              font-weight:400;
              letter-spacing:1px;
            }
            body {
              background-color: #000000;
            }
            .content{
              position: absolute;
              z-index: 99999;
              overflow: hidden;
            }
            .container{
              width: 100%;
            }
            .logo img{
              width: 150px;
              margin : 20px;
            }
            .text{
              position: relative;
              z-index:99999;
              height:100%;
              width: 100%;
            }
            .text h1{
              position: absolute;
              width: 100%;
              height : 50px;
              padding : 0 20px;
              bottom: 100px;
              color: #ffffff;
              font-size: 32px;
              font-weight: 700;
              text-shadow: 2px 2px 10px #000000;
            }
            .text h2{
              position: absolute;
              width: 100%;
              height : 100px;
              padding : 0px 20px;
              bottom: 0px;
              color: #ffffff;
              font-size: 18px;
              text-shadow: 2px 2px 10px #000000;
            }
              .text1{
                display: none;
                text-align: center;
              }
            .form{
              position: relative;
              top: 50%;
              margin-top: -250px;
              height: 500px;
            }
            #select-country{
              z-index: 9999999;
            }
            .crossfade > figure {
              animation: imageAnimation 30s linear infinite 0s;
              backface-visibility: hidden;
              background-size: cover;
              background-position: center center;
              color: transparent;
              height: 100%;
              left: 0px;
              opacity: 0;
              position: absolute;
              top: 0px;
              width: 100%;
              z-index: 0;
            }



            @keyframes imageAnimation {
              0% {
                animation-timing-function: ease-in;
                opacity: 0;
              }
              8% {
                animation-timing-function: ease-out;
                opacity: 1;
              }
              30% {
                opacity: 1
              }
              48% {
                opacity: 0
              }
            }

            .MuiOtpInput-TextField{
              color: `+props.config.textColor+`;
              border: 1px solid `+props.config.textColor+`;
            }
            .MuiInputBase-input{
              color: `+props.config.textColor+`;
              font-size: 24px;
            } 

            @media screen and (max-width: 767px) {
              .text{
                display: none;
              }
              .text1{
                display: block;
              }
              .form{
                position: relative;
                top: 40%;
                padding: 20px;
              }
            }
        `}</style>
        {props.config.comp.photos? (props.config.comp.photos.map((photo,key) => (
            <style>{` 
                .crossfade > figure:nth-child(`+(key+1)+`) {
                  background-image: url('`+photo+`');
                  animation-delay: `+(key*6)+`s;
                } 
            `}</style>
        ))):('')}
        <div className="crossfade">
        {props.config.comp.photos? (props.config.comp.photos.map((photo,key) => (
            <figure></figure>
        ))):('')}
        </div>
        <div className="content" style={{width:'100%'}}>
          <Row>
          <Col md={8}>
              <div className="text">
                <div className="logo"><img src={props.config.comp.company_logo} /></div>
                <h1>{props.config.comp.title}</h1>
                <h2>{props.config.comp.description}</h2>
              </div>
            </Col>
            <Col md={4} style={{backgroundColor: 'rgba(0, 0, 0, 0.4)', height:'100vh', padding:0}}>
              
              <div className="form">
                  <div className="text1">
                      <div className="logo"><img src={props.config.comp.company_logo} /></div>
                  </div>
                    {form==1?(
                        <div className="login-wrap p-4 p-md-5">
                            <div className="d-flex">
                                <div className="w-100">
                                  <h3 className="mb-4" style={{color:props.config.textColor, fontSize:'28px'}}>Please fill in the form to connect {props.config.comp.company_name} Wifi.</h3>
                                </div>		
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="signin-form">
                                <div className="form-group mb-4">
                                  <Mui.TextField 
                                      type="text" 
                                      variant="standard"
                                      color="themePortal"
                                      InputLabelProps={{
                                        style: {
                                          color: props.config.textColor
                                        }
                                      }}
                                      InputProps={{
                                        style: {
                                          color: props.config.textColor
                                        }
                                      }}
                                      className={classes.transparentBackground}
                                      label="Your Name" required 
                                  aria-invalid={errors.name ? "true" : "false"}
                                  {...register("name", { required: true, maxLength: 30 })} />
                                  {errors.name && <span className="error">{errors.name.message}</span>}
                                  {error.filter(error => error.path === 'name').length > 0 && <span className="error">{error.filter(error => error.path === 'name')[0].msg}</span>}
                                </div>
                                <div className="form-group mb-3">
                                  <label className="label" htmlFor="whatsapp" style={{color:props.config.textColor}}>Whatsapp Number*</label>
                                    <MuiTelInput value={tel} 
                                        
                                      variant="standard"
                                      InputProps={{
                                        style: {
                                          color: props.config.textColor,
                                          borderBottom: '0.02em solid '+ props.config.borderColor
                                        }
                                      }}
                                      style={{border:'0px solid'}}
                                      className={classes.transparentBackground}
                                      size="small" onChange={telChange} />
                                    {error.filter(error => error.path === 'wa_number').length > 0 && <span className="error">{error.filter(error => error.path === 'wa_number')[0].msg}</span>}
                                </div>
                                <div className="form-group mb-5">
                                  <Mui.TextField 
                                      type="email" 
                                      variant="standard"
                                      color="themePortal"
                                      InputLabelProps={{
                                        style: {
                                          color: props.config.textColor
                                        }
                                      }}
                                      InputProps={{
                                        style: {
                                          color: props.config.textColor
                                        }
                                      }}
                                      className={classes.transparentBackground}
                                      label="Email" required 
                                      aria-invalid={errors.name ? "true" : "false"}
                                        {...register("email", {
                                          required: "required",
                                          pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: "Entered value does not match email format",
                                          },
                                        })}
                                      />
                                      {errors.email && <span role="alert" style={{color:'red', margin:'3px', fontSize:'14px'}}>{errors.email.message}</span>}
                                      {/*error.filter(error => error.path === 'email').length > 0 && <span className="error">{error.filter(error => error.path === 'email')[0].msg}</span>*/}
                                </div>
                                <div className="form-group">
                                  <Mui.Button
                                      type="submit"
                                      startIcon={<MuiIcon.AppRegistration />}
                                      variant="outlined" 
                                      className="form-control"
                                      style={{
                                        background:props.config.buttonColor,
                                        color:props.config.buttonTextColor,
                                        border: '0px solid',
                                        fontSize: '14px',
                                        height: '50px'
                                      }}
                                    >
                                      {loading==true?(<>PLEASE WAIT... <Mui.CircularProgress size="20px" style={{marginLeft:"20px", color:'+props.config.textColor+'}} /></>):('SUBMIT')}
                                    </Mui.Button>
                                </div>
                            </form>
                        </div>
                    ):('')}
                    {form==2?(
                        <div className="login-wrap p-4 p-md-5">
                            <div className="d-flex">
                                <div className="w-100">
                                  <h3 style={{color:props.config.textColor, fontSize:'26px'}}>Whatsapp verification.</h3>
                                  <h5 className="mb-4" style={{color:props.config.textColor, fontSize:'22px'}}>Enter the code we just sent on your phone.</h5>
                                </div>		
                            </div>
                            <form onSubmit={handleSubmit(authCode)} className="signin-form">
                              <div className="form-group d-flex flex-row mt-2">
                                  <MuiOtpInput id="otp" value={otp} onChange={otpChange} enableFocus autoFocus />
                              </div>
                              <div className="form-group text-center mt-5">
                                    {seconds > 0 ? (<p className="label" style={{color:props.config.textColor, fontSize:'16px'}}>Time Left : {seconds}</p>):('')}
                                    {seconds == 0 ? (
                                      <p className="d-block" style={{color:props.config.textColor, fontSize:'16px'}}>Didn't receive the code? &nbsp; &nbsp; 
                                      <Mui.Button
                                          type="button"
                                          startIcon={<MuiIcon.Sync />}
                                          variant="solid" 
                                          style={{
                                            border:'1px solid '+ props.config.textColor,
                                            color:props.config.textColor,
                                            fontSize: '14px',
                                            height: '50px'
                                          }}
                                          onClick={()=>{
                                            const time = new Date();
                                            time.setSeconds(time.getSeconds() + timer);
                                            resend()
                                            restart(time)
                                        }}
                                        >
                                          {loading==true?(<>Please wait..<Mui.CircularProgress size="20px" style={{marginLeft:"20px", color:props.config.textColor}} /></>):('RESEND')}
                                        </Mui.Button>
                                    </p>):('')}
                                    <h6 style={{color:'red', textAlign:'center'}}>{error}</h6>
                              </div>
                              <div className="form-group mt-5">
                                      <Mui.Button
                                          type="submit"
                                          startIcon={<MuiIcon.TapAndPlay />}
                                          variant="outlined" 
                                          className="form-control"
                                          style={{
                                            background:props.config.buttonColor,
                                            color:props.config.buttonTextColor,
                                            border: '0px solid',
                                            fontSize: '14px',
                                            height: '50px'
                                          }}
                                        >
                                          {loading==true?(<>PLEASE WAIT... <Mui.CircularProgress size="20px" style={{marginLeft:"20px", color:'+props.config.textColor+'}} /></>):('CONNECT')}
                                        </Mui.Button>
                                </div>
                            </form>
                        </div>
                    ):('')}
              </div>
            </Col>
          </Row>
        </div>
      </>
  )
  
}

export default Theme1;
